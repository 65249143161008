import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/CompaniesSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.companies || initialState;

export const selectCompanies = createSelector([selectDomain], (state) => state.companies);
export const selectAlertCompanies = createSelector(
  [selectDomain],
  (state) => state.companies,
);
export const selectProfileLoading = createSelector(
  [selectDomain],
  (state) => state.profileLoading,
);
export const selectCompaniesLoading = createSelector(
  [selectDomain],
  (state) => state.companiesLoading,
);
export const selectRecommendedCompanies = createSelector(
  [selectDomain],
  (state) => state.recommendedCompanies,
);
export const selectSmartAccountToastMessage = createSelector(
  [selectDomain],
  (state) => state.smartAccountToastMessage,
);
export const selectRecommendedCompaniesLoading = createSelector(
  [selectDomain],
  (state) => state.recommendedCompaniesLoading,
);
export const selectCompaniesEnded = createSelector(
  [selectDomain],
  (state) => state.companiesEnded,
);

export const selectCompanyName = createSelector(
  [selectDomain],
  (state) => state.companyProfile?.companyName,
);

export const selectCompanyProfile = createSelector(
  [selectDomain],
  (state) => state.companyProfile,
);

export const selectCompaniesPageNumber = createSelector(
  [selectDomain],
  (state) => state.companiesPageNumber,
);

export const selectStatus = createSelector(
  [selectDomain],
  (state) => state.status,
);

export const selectSearchedCompanies = createSelector(
  [selectDomain],
  (state) => state.searchedCompanies,
);

export const selectCompanySecFilings = createSelector(
  [selectDomain],
  (state) => state.companySecFilings,
);
export const selectCompanySecFilingsLoading = createSelector(
  [selectDomain],
  (state) => state.companySecFilingsLoading,
);

export const selectCompanyPulse = createSelector(
  [selectDomain],
  (state) => state.companyPulse,
);
export const selectCompanyPulseLoading = createSelector(
  [selectDomain],
  (state) => state.companyPulseLoading,
);

export const selectCompanyNews = createSelector(
  [selectDomain],
  (state) => state.companyNews,
);

export const selectCompanyBenefits = createSelector(
  [selectDomain],
  (state) => state.companyBenefits,
);

export const selectCompanyVideo = createSelector(
  [selectDomain],
  (state) => state.companyVideo,
);

export const selectCompanyVideoLoading = createSelector(
  [selectDomain],
  (state) => state.companyVideoLoading,
);

export const selectVideoPageNumber = createSelector(
  [selectDomain],
  (state) => state.companyVideoPageNumber,
);

export const selectCompanyVideoEnded = createSelector(
  [selectDomain],
  (state) => state.companyVideoEnded,
);

export const selectCompanyIntentData = createSelector(
  [selectDomain],
  (state) => state.companyIntent,
);

export const selectCompanyStaff = createSelector(
  [selectDomain],
  (state) => state.companyStaff,
);

export const selectCompanyStaffLoading = createSelector(
  [selectDomain],
  (state) => state.companyStaffLoading,
);

export const selectFiltersArray = createSelector(
  [selectDomain],
  (state) => state.filtersArray,
);
export const selectNewsFiltersArray = createSelector(
  [selectDomain],
  (state) => state.companyNewsFilters,
);
export const selectNewsFiltersLoading = createSelector(
  [selectDomain],
  (state) => state.companyNewsFiltersLoading,
);
export const selectCompanyIntentLoading = createSelector(
  [selectDomain],
  (state) => state.companyIntentLoading,
);
export const selectNewsLoading = createSelector(
  [selectDomain],
  (state) => state.companyNewsLoading,
);

export const selectCompanyNewsEnded = createSelector(
  [selectDomain],
  (state) => state.companyNewsEnded,
);

export const selectCompanyNewsPageNumber = createSelector(
  [selectDomain],
  (state) => state.companyNewsPageNumber,
);

export const selectCompanyNewsFiltersSelected = createSelector(
  [selectDomain],
  (state) => state.companyNewsFiltersSelected,
);

export const selectDownloadedProfilePDF = createSelector(
  [selectDomain],
  (state) => state.downloadedPDFProfile,
);
export const selectDownloadedProfilePPT = createSelector(
  [selectDomain],
  (state) => state.downloadedPPTProfile,
);
export const selectProfileDownloadPdfLoading = createSelector(
  [selectDomain],
  (state) => state.profileDownloadPdfLoading,
);
export const selectProfileDownloadPptLoading = createSelector(
  [selectDomain],
  (state) => state.profileDownloadpptLoading,
);
export const selectChartLoading = createSelector(
  [selectDomain],
  (state) => state.stocksLoading,
);
export const selectCompanyStockCharts = createSelector(
  [selectDomain],
  (state) => state.stocksData,
);
export const selectAddCompanyPopup = createSelector(
  [selectDomain],
  (state) => state.addCompanyPopup,
);
export const selectCompanyNewsFiltersApplied = createSelector(
  [selectDomain],
  (state) => state.companyNewsFiltersApplied,
);
export const selectNotableStaff = createSelector(
  [selectDomain],
  (state) => state.companyNotableStaff,
);
export const selectNotableStaffLoading = createSelector(
  [selectDomain],
  (state) => state.notableStaffLoading,
);
export const selectBuyerStaffLoading = createSelector(
  [selectDomain],
  (state) => state.buyerStaffLoading,
);
export const selectBuyerStaffPerson = createSelector(
  [selectDomain],
  (state) => state.accountPlan.buyerPerson,
);
export const selectNotableStaffPage = createSelector(
  [selectDomain],
  (state) => state.notableStaffPage,
);
export const selectNotableStaffCount = createSelector(
  [selectDomain],
  (state) => state.notableStaffCount,
);
export const selectNotableStaffFilters = createSelector(
  [selectDomain],
  (state) => state.notableStaffFilters,
);
export const selectNotableStaffFiltersLoading = createSelector(
  [selectDomain],
  (state) => state.notableStaffFiltersLoading,
);
export const selectNotableSelectedFilters = createSelector(
  [selectDomain],
  (state) => state.notableSelectedFilters,
);
export const selectNotableStaffTitle = createSelector(
  [selectDomain],
  (state) => state.notableStaffTitle,
);
export const selectNotableStaffExportLoading = createSelector(
  [selectDomain],
  (state) => state.notableStaffExportLoading,
);
export const notableStaffCompanyName = createSelector(
  [selectDomain],
  (state) => state.companyName,
);
export const selectCompanyCount = createSelector(
  [selectDomain],
  (state) => state.companiesCount,
);
export const companyWorkbenchData = createSelector(
  [selectDomain],
  (state) => state.workbenchStats.data,
);

export const companyWorkbenchLoading = createSelector(
  [selectDomain],
  (state) => state.workbenchStats.loading,
);

export const selectIsEmptyStats = createSelector(
  [selectDomain],
  (state) => state.workbenchStats.isEmptyWorkbenchStats,
);

export const selectFinanialCall = createSelector(
  [selectDomain],
  (state) => state.finanialApiCall,
);

export const selectSocialCall = createSelector(
  [selectDomain],
  (state) => state.socialApiCall,
);

export const selectVideoCall = createSelector(
  [selectDomain],
  (state) => state.videoApiCall,
);

export const selectStaffCall = createSelector(
  [selectDomain],
  (state) => state.staffApiCall,
);

export const selectCompanyDetailsCall = createSelector(
  [selectDomain],
  (state) => state.companyDetailApiCall,
);
export const selectCompetitorCall = createSelector(
  [selectDomain],
  (state) => state.competitorsApiCall,
);

export const selectCompetitorsFlurry = createSelector(
  [selectDomain],
  (state) => state.competitorsFlurry,
);

export const selectStaffFlurry = createSelector(
  [selectDomain],
  (state) => state.staffFlurry,
);

export const selectFinanialFlurry = createSelector(
  [selectDomain],
  (state) => state.finanialFlurry,
);

export const selectCompanyDetailFlurry = createSelector(
  [selectDomain],
  (state) => state.companyDetailFlurry,
);

export const selectSocialFlurry = createSelector(
  [selectDomain],
  (state) => state.socialFlurry,
);

export const selectVideoFlurry = createSelector(
  [selectDomain],
  (state) => state.videoFlurry,
);

export const selectNewsFlurry = createSelector(
  [selectDomain],
  (state) => state.newsFlurry,
);

export const selectNewsCall = createSelector(
  [selectDomain],
  (state) => state.newsApiCall,
);

export const selectMessage = createSelector([selectDomain], (state) => state.message);

export const selectNewsFilterApplied = createSelector(
  [selectDomain],
  (state) => state.newsFilterApplied,
);
export const selectCompaniesExportLoading = createSelector(
  [selectDomain],
  (state) => state.exportCompaniesLoading,
);
export const selectCompaniesExportUrl = createSelector(
  [selectDomain],
  (state) => state.exportCompaniesUrl,
);

export const selectisComparisonLoading = createSelector(
  [selectDomain],
  (state) => state.compareCompaniesLoading,
);
export const selectCompanyComparisonData = createSelector(
  [selectDomain],
  (state) => state.companyComparisonData,
);
export const selectComparisonRows = createSelector(
  [selectDomain],
  (state) => state.comparisonRows,
);
export const selectisComparisonRemoved = createSelector(
  [selectDomain],
  (state) => state.isComparisonRemoved,
);

export const selectSWOTLoading = createSelector(
  [selectDomain],
  (state) => state.swotLoading,
);
export const selectSWOTData = createSelector(
  [selectDomain],
  (state) => state.swotData,
);

export const selectSWOTRefreshLoading = createSelector(
  [selectDomain],
  (state) => state.swotRefreshLoading,
);

export const selectRefreshCompanyIds = createSelector(
  [selectDomain],
  (state) => state.refreshCompanyIds,
);

export const selectSwotRefreshedCompany = createSelector(
  [selectDomain],
  (state) => state.swotRefreshedCompany,
);

export const selectAccountPlanForm = createSelector(
  [selectDomain],
  (state) => state.accountPlanForm,
);

export const selectAccountPlanLoading = createSelector(
  [selectDomain],
  (state) => state.accountPlanLoading,
);

export const selectAccountPlanLoaded = createSelector(
  [selectDomain],
  (state) => state.accountPlanLoaded,
);

export const selectAccountPlanUrl = createSelector(
  [selectDomain],
  (state) => state.accountPlanUrl,
);

export const selectAccountPlanCompanyId = createSelector(
  [selectDomain],
  (state) => state.accountPlanCompaniesIds,
);

export const selectAccountPlanUserAllowDownload = createSelector(
  [selectDomain],
  (state) => state.accountPlanAllowDownload,
);

export const selectAccountPlanPreviewUrl = createSelector(
  [selectDomain],
  (state) => state.accountPlanPreviewUrl,
);

export const selectAccountPlanStatus = createSelector(
  [selectDomain],
  (state) => state.companyProfile?.accountPlan.status,
);

export const selectAccountPlanFormFilled = createSelector(
  [selectDomain],
  (state) => state.accountPlanFormFilled,
);

export const selectAccountFormLoading = createSelector(
  [selectDomain],
  (state) => state.accountFormLoading,
);

export const selectAccountPlanIds = createSelector(
  [selectDomain],
  (state) => state.accountPlanIds,
);

export const selectGeneratedAccountPlans = createSelector(
  [selectDomain],
  (state) => state.generatedAccountPlans,
);

export const selectAccountPlanAllowed = createSelector(
  [selectDomain],
  (state) => state.accountPlanAllowed,
);

export const selectSAPAllowed = createSelector(
  [selectDomain],
  (state) => state.sapAllowed,
);

export const selectSAPDownloadAllowed = createSelector(
  [selectDomain],
  (state) => state.companyProfile?.userAccountPlanDetails?.allowDownload,
);

export const selectCompanyId = createSelector(
  [selectDomain],
  (state) => state.companyProfile?.companyId,
);

export const selectProductCategory = createSelector(
  [selectDomain],
  (state) => state.productCategory,
);

export const selectProductDescription = createSelector(
  [selectDomain],
  (state) => state.productDescription,
);
export const selectAccountPlanOpen = createSelector(
  [selectDomain],
  (state) => state.accountPlanDrawerOpen,
);
export const selectDesignationsLoading = createSelector(
  [selectDomain],
  (state) => state.designationsLoading,
);

export const selectDesignationsLoaded = createSelector(
  [selectDomain],
  (state) => state.designationsLoaded,
);

export const selectAccountSelectedGroups = createSelector(
  [selectDomain],
  (state) => state.accountPlan.selectedGroups,
);

export const selectAccountSelectedPeople = createSelector(
  [selectDomain],
  (state) => state.accountPlan.selectedPeople,
);

export const selectShowSelectedGroups = createSelector(
  [selectDomain],
  (state) => state.accountPlan.showSelectedGroups,
);

export const selectShowSelectedPeople = createSelector(
  [selectDomain],
  (state) => state.accountPlan.showSelectedPeople,
);

export const selectPeopleToShow = createSelector(
  [selectDomain],
  (state) => state.accountPlan.peopleToShow,
);

export const selectGroupsToShow = createSelector(
  [selectDomain],
  (state) => state.accountPlan.groupsToShow,
);

export const selectAccountDesignations = createSelector(
  [selectDomain],
  (state) => state.accountPlan.designations,
);

export const selectShowDesignationsLoadingScreen = createSelector(
  [selectDomain],
  (state) => state.accountPlan.showDesignationsLoadingScreen,
);

export const selectLoadingLiveIds = createSelector(
  [selectDomain],
  (state) => state.accountPlan.loadingLiveIds,
);

export const selectSapNotableStaff = createSelector(
  [selectDomain],
  (state) => state.accountPlan.notableStaff,
);

export const selectSapNotableStaffPage = createSelector(
  [selectDomain],
  (state) => state.accountPlan.notableStaffPage,
);

export const selectBuyerStaffPage = createSelector(
  [selectDomain],
  (state) => state.accountPlan.buyerStaffPage,
);
export const selectBuyerStaff = createSelector(
  [selectDomain],
  (state) => state.accountPlan.buyerStaff,
);
export const selectBuyerStaffCount = createSelector(
  [selectDomain],
  (state) => state.accountPlan.buyerStaffCount,
);

export const selectSapNotableStaffCount = createSelector(
  [selectDomain],
  (state) => state.accountPlan.notableStaffCount,
);

export const selectLiveExecIds = createSelector(
  [selectDomain],
  (state) => state.accountPlan.liveExecIds,
);
export const selectBuyerPersonId = createSelector(
  [selectDomain],
  (state) => state.accountPlan.buyerPersonId,
);

export const selectSocketAccountPlanStatus = createSelector(
  [selectDomain],
  (state) => state.accountPlanStatus,
);

export const selectBuyerPersonLoading = createSelector(
  [selectDomain],
  (state) => state.accountPlan.buyerPersonLoading,
);

export const selectCompanyNotesAvaliable = createSelector(
  [selectDomain],
  (state) => state.companyNotesAvailable,
);

export const selectCompanyNewNotes = createSelector(
  [selectDomain],
  (state) => state.newNotes,
);

export const selectuserAccountDetails = createSelector(
  [selectDomain],
  (state) => state.companyProfile?.userAccountPlanDetails,
);

export const selectLimitPopupSAP = createSelector(
  [selectDomain],
  (state) => state.showLimitPopupSAP,
);

export const selectAccountPlanHistory = createSelector(
  [selectDomain],
  (state) => state.accountPlanHistory,
);

export const selectAccountPlanHistoryLoading = createSelector(
  [selectDomain],
  (state) => state.accountPlanHistoryLoading,
);

export const selectSapSortType = createSelector(
  [selectDomain],
  (state) => state.sapSortType,
);

export const selectSapSort = createSelector(
  [selectDomain],
  (state) => state.sapSort,
);

export const selectDeleteAccountPlanLoading = createSelector(
  [selectDomain],
  (state) => state.deleteAccountPlanLoading,
);

export const selectCurrentSapCompanyId = createSelector(
  [selectDomain],
  (state) => state.currentSapCompanyId,
);

export const selectDocumentSections = createSelector(
  [selectDomain],
  (state) => state.accountPlanForm[4]?.questions[0]?.sections,
);

export const selectCompetitors = createSelector(
  [selectDomain],
  (state) => state.accountPlanForm[3]?.questions[0]?.competitors,
);
export const showAccountHistory = createSelector(
  [selectDomain],
  (state) => state.showAccountHistory,
);

export const selectAccountPlanFailed = createSelector(
  [selectDomain],
  (state) => state.accountPlan.showAccountPlanFailed,
);

export const selectShowSapPreview = createSelector(
  [selectDomain],
  (state) => state.showSapPreview,
);

export const selectGenerationFail = createSelector(
  [selectDomain],
  (state) => state.generationFail,
);

export const selectCompaniesExported = createSelector(
  [selectDomain],
  (state) => state.companiesExported,
);

export const selectSapSelectedDocs = createSelector(
  [selectDomain],
  (state) => state.accountPlanForm[1]?.questions[0]?.documents,
);

export const selectedCompareCompaniesIds = createSelector(
  [selectDomain],
  (state) => state.selectedCompareCompaniesIds,
);

export const selectedSortingType = createSelector(
  [selectDomain],
  (state) => state.sortingType,
);
export const selectCurrentSections = createSelector(
  [selectDomain],
  (state) => state.currentSections,
);

export const selectLatestEarningScroll = createSelector(
  [selectDomain],
  (state) => state.latestEarningScroll,
);

export const selectDownloadUrl = createSelector(
  [selectDomain],
  (state) => state.fileDownloadURL,
);
export const selectPreviewUrl = createSelector(
  [selectDomain],
  (state) => state.fileViewUrl,
);

export const selectShareUrl = createSelector(
  [selectDomain],
  (state) => state.fileShareUrl,
);

export const selectShareFileLoading = createSelector(
  [selectDomain],
  (state) => state.fileShareLoading,
);

export const selectSubscribedCount = createSelector(
  [selectDomain],
  (state) => state.subscribedCount,
);

export const selectLoadMore = createSelector(
  [selectDomain],
  (state) => state.loadMore,
);
