/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */

import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/OnboardingSlice';
import { actions as companyAction } from 'Slice/CompaniesSlice';
import { actions as helperActions } from 'Slice/HelperSlice';
import { PayloadAction } from '@reduxjs/toolkit';

import axios from 'Utils/Axios';

import { API } from 'Utils/Constants';
// import {
//   GetCompaniesRequest,
// } from 'Types/RequestBodies';
import {
  UserOnboardingDataResponse,
  UserOnboardingRecommendedData,
} from 'Types/OnboardingTypes';
import { DailyIQCompanies } from 'Types/GlobalTypes';
import { GlobalResponse } from 'Types/ResponseBodies';
import { CompaniesDataResponse } from 'Types/CompaniesTypes';

export function* getUserOnboardingData(dispatched: PayloadAction<object>) {
  try {
    const response: UserOnboardingDataResponse = yield call(
      axios.post,
      API.GET_RECOMMENDED_USER_ONBOARDING,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setUserOnboardingData(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getRecommendedPeopleOnboarding(dispatched: PayloadAction<object>) {
  try {
    const response: UserOnboardingRecommendedData = yield call(
      axios.post,
      API.GET_ONBOARDING_RECOMMENDED_PEOPLE,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setRecommendedPeopleOnboarding(response.response[0]));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getDailyIQCompanies(dispatched: PayloadAction<DailyIQCompanies>) {
  const payload = dispatched?.payload;
  const data: any = {
    offset: payload.offset,
    ...payload.searchTerm && { searchTerm: payload.searchTerm },
    ...payload.subscribedCompanies && { subscribedCompanies: payload.subscribedCompanies },
    ...payload.unsubscribedCompanies && { unsubscribedCompanies: payload.unsubscribedCompanies },
  };
  try {
    const response: CompaniesDataResponse = yield call(
      axios.post,
      API.GET_DAILYIQ_COMPANIES,
      data,
    );
    if (response?.status === true) {
      if (response?.response?.companies) {
        const { offset } = dispatched?.payload ?? {};
        if (offset === 0) {
          yield put(companyAction.setSearchCompanies(response));
        } else {
          yield put(companyAction.setCompanies({ ...response, loadMore: payload.loadMore }));
        }
      }
      if (!response?.response?.companies) {
        yield put(companyAction.setDailyIQLoading(false));
        yield put(actions.setDailyIqCompanies(true));
        yield put(helperActions.setNotification({
          message: 'Companies status has changed successfully',
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: true,
        }));
      }
    } else {
      yield put(actions.setDailyIqCompanies(false));
      yield put(helperActions.setNotification({
        message: 'Something went wrong',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.setDailyIqCompanies(false));
    yield put(helperActions.setNotification({
      message: 'Something went wrong',
      proTip: false,
      rightPlacement: false,
      groupId: 0,
      classnameFlag: false,
    }));
  }
}

export function* onboardingSaga() {
  yield takeLatest(actions.getUserOnboardingData.type, getUserOnboardingData);
  yield takeLatest(actions.getRecommendedPeopleOnboarding.type, getRecommendedPeopleOnboarding);
  yield takeLatest(companyAction.getDailyIQCompanies.type, getDailyIQCompanies);
}
